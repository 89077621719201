import { seniorityMessages } from "../../shared/messages";
import Seniority from "../../types/Seniority";
import { Degree } from "../../types/Degree";
import { useLanguage } from "../../contexts/LanguageContext";
import { SalaryHistory } from "../../types/Employee";
import { getDecimalValue } from "./functions";

interface SalaryHistoryDetailProps {
  history: SalaryHistory;
}

const SalaryHistoryDetail = ({ history }: SalaryHistoryDetailProps) => {
  const { messages } = useLanguage();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        margin: "0 0.5rem 0",
      }}
    >
      <span>
        {messages.information.approvedAt}:{" "}
        {new Date(history.teamLeaderApprovalDate).toLocaleDateString()}
      </span>
      <span>
        {messages.information.effectiveFrom}:{" "}
        {new Date(history.effectiveFrom).toLocaleDateString()}
      </span>
      <span>
        {messages.comment}: {history.reason}
      </span>
      <span>
        {messages.leadLevel}:{" "}
        {seniorityMessages[history.seniority as Seniority].label}
      </span>
      <span>
        {messages.information.positionTitle}: {history.jobTitle}
      </span>
      <span>
        {messages.degree}: {Degree[history.degree]}
      </span>
      <span>
        {messages.information.domain}: {history.domain}
      </span>
      <span>
        {messages.fixum}: {getDecimalValue(history.fixum)}
        {history.currency}
      </span>
      <span>
        {messages.variable}: {getDecimalValue(history.variable)}
        {history.currency}
      </span>
      {!!history.companyCar && (
        <span>
          {messages.companyCar}:{" "}
          {history.companyCar
            ? getDecimalValue(history.companyCar)
            : getDecimalValue(0)}
          {history.currency}
        </span>
      )}
      <span>
        {messages.variablePortion}:{" "}
        {getDecimalValue(history.variablePercent, true)}%
      </span>
      <span>
        {messages.information.singlePayment}:{" "}
        {getDecimalValue(history.singlePayment)}
        {history.currency}
      </span>
      {history.difference !== null ? (
        <span>
          {messages.percentageIncrease}:{" "}
          {getDecimalValue(history.difference, true)}%
        </span>
      ) : null}
      <span>
        {messages.information.weeklyHours}: {history.hours} {messages.weekLabel}
      </span>
      <span>
        {messages.responsibility}:{" "}
        {history.teamlead ? messages.information.yes : messages.information.no}
      </span>
    </div>
  );
};

export default SalaryHistoryDetail;

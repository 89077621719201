const IconArrowLeft = () => (
  <svg width={10} height={18} viewBox="0 0 10 18">
    <path
      d="M0.500001 9.03462L8.5 0.111816L9.93333 1.71049L3.33333 9.03462L9.9 16.3588L8.46667 17.9574L0.500001 9.03462Z"
      fill="currentColor"
    />
  </svg>
);

export default IconArrowLeft;

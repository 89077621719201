import { Employee, ICompanyTree, Statistics } from "../types/Employee";
import { seniorityMessages } from "../shared/messages";

type ObjectType<T> = { [key: string]: T };

export const getKeyByValue = <T>(
  obj: ObjectType<T>,
  value: T,
): string | null => {
  const keys = Object.keys(obj);
  const values = Object.values(obj);

  const index = values.indexOf(value);
  return index !== -1 ? keys[index] : null;
};

//  Convert a "dd-MM-YYYY" string into a Date object
export const convertToDate = (dateString: string) => {
  let d = dateString.split("-");
  return new Date(d[2] + "/" + d[1] + "/" + d[0]);
};

// Capitalize first letter of a string
export const capitalizeFirstLetter = (str: string): string => {
  return str.length > 0 ? str[0].toUpperCase() + str.substring(1) : str;
};

// Merge object into found upn
export const mergeObjectByUpn = (
  arr: ICompanyTree[],
  targetUpn: string,
  mergeObject: Employee,
): boolean => {
  for (let i = 0; i < arr.length; i++) {
    const employee = arr[i];
    if (employee.upn === targetUpn) {
      if (!(employee as Employee).birthDate) {
        // Merge the provided mergeObject into the found object
        arr[i] = { ...arr[i], ...mergeObject };
      }
      return true; // Object merged successfully
    }

    if (mergeObjectByUpn(employee.team, targetUpn, mergeObject)) {
      return true; // Object merged successfully in a nested team
    }
  }

  return false; // Target id not found in this array
};

// Filter Objects With Property
export const filterObjectsWithProperty = (
  arr: ICompanyTree[],
): ICompanyTree[] => {
  return arr.reduce((filteredArr, obj) => {
    if (obj.hasOwnProperty("birthDate")) {
      // Include the object in the filtered array
      filteredArr.push(obj);
    }

    if (obj.team && obj.team.length > 0) {
      // Recursively filter objects in nested children arrays
      const filteredChildren = filterObjectsWithProperty(obj.team);
      if (filteredChildren.length > 0) {
        // If there are filtered children, add them to the current object
        obj.team = filteredChildren;
        if (!filteredArr.find((e) => e.upn === obj.upn)) {
          filteredArr.push(obj);
        }
      }
    }

    return filteredArr;
  }, [] as ICompanyTree[]);
};

// Sort given employees by presence of sub-team
export const sortByTeamPresence = (arr: Employee[]): Employee[] => {
  return arr.sort((a, b) => {
    const hasTeamA = a.team && a.team.length > 0;
    const hasTeamB = b.team && b.team.length > 0;

    if (hasTeamA && !hasTeamB) {
      return -1; // Object A has a team, but B does not, so A comes first
    } else if (!hasTeamA && hasTeamB) {
      return 1; // Object B has a team, but A does not, so B comes first
    } else {
      return 0; // Both objects have a team or neither has a team, order doesn't matter
    }
  });
};

export const findUserStatistics = (
  targetUpn: string,
  statistics: Statistics[],
): Statistics | undefined => {
  if (statistics?.length) {
    return statistics.find((statistic) => statistic.upn === targetUpn);
  }
  return undefined;
};

export const isFirstSeniorityHigherThanSecond = (
  first: string,
  second: string,
) =>
  first &&
  second &&
  Object.keys(seniorityMessages).indexOf(first) >
    Object.keys(seniorityMessages).indexOf(second);

export const getAggregatedStatistics = (
  statistics: Statistics[],
  employees: Employee[],
): Statistics | undefined => {
  const topLevel = employees.filter(
    (e) => e.teamleadUpn === "-1" || e.teamleadUpn === null,
  );
  const topLevelUpns = topLevel.map((e) => e.upn);
  const topLevelStatistics = statistics.filter((s) =>
    topLevelUpns.includes(s.upn),
  );

  if (topLevelUpns.length > 0) {
    const aggregatedStatistics = topLevelStatistics.reduce(
      (previous, current) => ({
        upn: previous.upn,
        totalOldSalary: previous.totalOldSalary + current.totalOldSalary,
        totalNewSalary: previous.totalNewSalary + current.totalNewSalary,
        difference: previous.difference + current.difference,
        differencePercent:
          previous.differencePercent + current.differencePercent,
        numOfEmployees: previous.numOfEmployees + current.numOfEmployees,
        numOfPromotions: previous.numOfPromotions + current.numOfPromotions,
        numOfNewManagers: previous.numOfNewManagers + current.numOfNewManagers,
        totalBonus: previous.totalBonus + current.totalBonus,
        budgetBonus: previous.budgetBonus + current.budgetBonus,
        budgetSalary: previous.budgetSalary + current.budgetSalary,
        avgNewSalary: undefined,
        sdNewSalary: undefined,
      }),
      {
        upn: "aggregated",
        totalOldSalary: 0,
        totalNewSalary: 0,
        difference: 0,
        differencePercent: 0,
        numOfEmployees: 0,
        numOfPromotions: 0,
        numOfNewManagers: 0,
        totalBonus: 0,
        budgetBonus: 0,
        budgetSalary: 0,
        avgNewSalary: undefined,
        sdNewSalary: undefined,
      },
    );
    aggregatedStatistics.differencePercent =
      (aggregatedStatistics.totalNewSalary /
        aggregatedStatistics.totalOldSalary -
        1) *
      100;
    return aggregatedStatistics;
  }
  return undefined;
};

import { Tooltip } from "react-tooltip";
import IconTally from "../icons/IconTally";
import { Statistics } from "../../types/Employee";
import { useLanguage } from "../../contexts/LanguageContext";
import IconIncrease from "../icons/IconIncrease";
import IconBill from "../icons/IconBill";
import { getCurrentPlanningYear, getPreviousYear } from "../../shared/years";

interface EmployeeStatisticsProps {
  employeeStatistics?: Statistics;
}

const EmployeeStatistics = ({
  employeeStatistics,
}: EmployeeStatisticsProps) => {
  const { messages } = useLanguage();
  const gapMargin = { gap: "8px", marginLeft: "8px" };

  return employeeStatistics ? (
    <>
      <div
        data-tooltip-id="sum-current-year"
        data-tooltip-content={`${
          messages.sumNormedSalary
        } ${getPreviousYear()}`}
        className="flex-row-items-center"
        style={gapMargin}
      >
        <IconBill /> {messages.sum} {getPreviousYear()}
        <Tooltip id="sum-current-year" />
        <span className="bold-nowrap">
          {employeeStatistics.totalOldSalary.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          EUR{/* TODO: Currency here */}
        </span>
      </div>

      <div
        data-tooltip-id="sum-next-year"
        data-tooltip-content={`${
          messages.sumNormedSalary
        } ${getCurrentPlanningYear()}`}
        className="flex-row-items-center"
        style={gapMargin}
      >
        <IconBill /> {messages.sum} {getCurrentPlanningYear()}
        <Tooltip id="sum-next-year" />
        <span className="bold-nowrap">
          {employeeStatistics.totalNewSalary.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          EUR{/* TODO: Currency here */}
        </span>
      </div>

      <div
        data-tooltip-id="sum-bonus"
        data-tooltip-content={`${
          messages.sumBonusTooltip
        } ${getCurrentPlanningYear()}`}
        className="flex-row-items-center"
        style={gapMargin}
      >
        <IconBill /> {messages.sumBonus}
        <Tooltip id="sum-bonus" />
        <span className="bold-nowrap">
          {employeeStatistics.totalBonus.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          EUR{/* TODO: Currency here */}
        </span>
      </div>

      <div
        data-tooltip-id="increase-absolute"
        data-tooltip-content={messages.absoluteSumTooltip}
        className="flex-row-items-center"
        style={gapMargin}
      >
        <IconIncrease size={24} /> {messages.absolute}
        <Tooltip id="increase-absolute" />
        <span className="bold-nowrap">
          {employeeStatistics.difference.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          EUR{/* TODO: Currency here */}
        </span>
      </div>
      <div
        data-tooltip-id="amount-employees"
        data-tooltip-content={messages.amountOfEmployeesTooltip}
        className="flex-row-items-center"
        style={gapMargin}
      >
        <IconTally /> {messages.employees}
        <Tooltip id="amount-employees" />
        <span className="bold-nowrap">{employeeStatistics.numOfEmployees}</span>
      </div>
      <div
        data-tooltip-id="amount-promotions"
        data-tooltip-content={messages.amountOfPromotionsTooltip}
        className="flex-row-items-center"
        style={gapMargin}
      >
        <IconTally /> {messages.promotions}
        <Tooltip id="amount-promotions" />
        <span className="bold-nowrap">
          {employeeStatistics.numOfPromotions}
        </span>
      </div>
      <div
        data-tooltip-id="amount-new-leads"
        data-tooltip-content={messages.amountOfNewLeadsTooltip}
        className="flex-row-items-center"
        style={{ ...gapMargin, marginRight: "8px" }}
      >
        <IconTally /> {messages.newLeads}
        <Tooltip id="amount-new-leads" />
        <span className="bold-nowrap">
          {employeeStatistics.numOfNewManagers}
        </span>
      </div>
    </>
  ) : (
    <div className="statistics-not-found">
      {messages.information.statisticsNotFound}
    </div>
  );
};

export default EmployeeStatistics;

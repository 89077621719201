import { useContext } from "react";

import Modal from "components/base/Modal";
import NotificationContext from "contexts/NotificationContext";

const Notification = () => {
  const [notification, setNotification] = useContext(NotificationContext);

  return (
    <Modal
      header={notification?.header}
      content={notification?.content}
      visible={!!notification}
      setVisible={() => setNotification(undefined)}
    />
  );
};

export default Notification;

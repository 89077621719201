const IconBill = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 48 48">
    <path
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M3 11h42v26H3z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M9.92 11c.05.33.08.66.08 1 0 3.31-2.69 6-6 6-.34 0-.67-.03-1-.08M3 30.08c.33-.05.66-.08 1-.08 3.31 0 6 2.69 6 6 0 .34-.03.67-.08 1M38.08 37c-.05-.33-.08-.66-.08-1 0-3.31 2.69-6 6-6 .34 0 .67.03 1 .08M45 17.92c-.33.05-.66.08-1 .08-3.31 0-6-2.69-6-6 0-.34.03-.67.08-1"
    />
    <circle
      cx="24"
      cy="24"
      r="7"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export default IconBill;

const IconPencil = () => (
  <svg width={19} height={19} viewBox="0 0 19 19">
    <path
      d="M1.5 17.5H2.6L13.675 6.42505L12.575 5.32505L1.5 16.4V17.5ZM16.85 5.35005L13.65 2.15005L14.7 1.10005C14.9833 0.816716 15.3333 0.675049 15.75 0.675049C16.1667 0.675049 16.5167 0.816716 16.8 1.10005L17.9 2.20005C18.1833 2.48338 18.325 2.83338 18.325 3.25005C18.325 3.66672 18.1833 4.01672 17.9 4.30005L16.85 5.35005ZM15.8 6.40005L3.2 19H0V15.8L12.6 3.20005L15.8 6.40005ZM13.125 5.87505L12.575 5.32505L13.675 6.42505L13.125 5.87505Z"
      fill="currentColor"
    />
  </svg>
);

export default IconPencil;

const IconIncrease = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16">
    <path
      d="M3.36669 13.3333L2.66669 12.6333L10.9667 4.33325H6.06669V3.33325H12.6667V9.93325H11.6667V5.03325L3.36669 13.3333Z"
      fill="currentColor"
    />
  </svg>
);

export default IconIncrease;

import { EmployeeDetails, EmployeeEditable } from "types/Employee";
import Card from "components/base/Card";
import Labeled from "components/base/Labeled";
import LabeledAmount from "components/base/LabeledAmount";
import Switch from "components/base/Switch";
import Seniority from "types/Seniority";
import { Dispatch, SetStateAction } from "react";
import {
  onChangeCurrentTargetValueNumber,
  onChangeCurrentTargetValueString,
  onChangeDegree,
  onChangeInvertBoolean,
  onChangeSeniority,
} from "./functions";
import { Degree } from "../../types/Degree";
import { Domain } from "../../types/Domain";
import { useLanguage } from "../../contexts/LanguageContext";
import { seniorityMessages } from "../../shared/messages";
import { getCurrentPlanningYear } from "../../shared/years";

interface EmployeeSalaryExpectationProps {
  employee: EmployeeDetails;
  editableSalaryHistory: EmployeeEditable;
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>;
}

const EmployeeSalaryExpectation = ({
  employee,
  editableSalaryHistory,
  setEditableSalaryHistory,
}: EmployeeSalaryExpectationProps) => {
  const { messages } = useLanguage();

  const latestSeniority = employee.lastSalaryHistory?.seniority;
  const currentSalaryHistory = employee.currentSalaryHistory;

  const editable = employee.availableActions === "APPROVE";
  const leadLevel = currentSalaryHistory.seniority;
  const plannedLeadLevel = leadLevel as keyof typeof Seniority;

  return (
    <Card className="employee-salary-expectation">
      <div className="employee-salary-expectation-row">
        <LabeledAmount
          editable={editable}
          amount={editableSalaryHistory.singlePayment}
          onChange={(event) =>
            onChangeCurrentTargetValueNumber(
              setEditableSalaryHistory,
              event,
              "singlePayment",
            )
          }
          label={messages.plannedSinglePayment + " " + getCurrentPlanningYear()}
          currency={editableSalaryHistory.currency}
        />
      </div>
      <div className="employee-salary-expectation-row">
        <Labeled
          editable={editable}
          label={messages.leadLevel + " " + getCurrentPlanningYear()}
          text={seniorityMessages[plannedLeadLevel].label}
        >
          <select
            onChange={(event) =>
              onChangeSeniority(setEditableSalaryHistory, event)
            }
            value={editableSalaryHistory.seniority}
          >
            {Object.keys(seniorityMessages)
              .filter(
                (key, index) =>
                  (!latestSeniority ||
                    (latestSeniority &&
                      index >=
                        Object.keys(seniorityMessages).indexOf(
                          latestSeniority,
                        ))) &&
                  seniorityMessages[key as Seniority].domains.includes(
                    currentSalaryHistory.domain as Domain,
                  ),
              )
              .map((key) => (
                <option key={key} value={key}>
                  {seniorityMessages[key as Seniority].label}
                </option>
              ))}
          </select>
        </Labeled>
        <div className="employee-salary-expectation-leadership">
          <Switch
            disabled={!editable}
            checked={editableSalaryHistory.teamlead}
            onClick={() =>
              onChangeInvertBoolean(
                editableSalaryHistory,
                setEditableSalaryHistory,
                "teamlead",
              )
            }
          />
          <small>{messages.responsibility}</small>
        </div>
      </div>

      <div className="employee-salary-expectation-row">
        <Labeled
          editable={editable}
          label={`${messages.degree} ${getCurrentPlanningYear()}`}
          text={messages.degreeLevels[editableSalaryHistory.degree]}
        >
          <select
            onChange={(event) =>
              onChangeDegree(setEditableSalaryHistory, event)
            }
            value={editableSalaryHistory.degree}
          >
            {Object.keys(Degree).map((key) => (
              <option key={key} value={key}>
                {messages.degreeLevels[key]}
              </option>
            ))}
          </select>
        </Labeled>
        <Labeled
          editable={editable}
          label={`${messages.position} ${getCurrentPlanningYear()}`}
          text={editableSalaryHistory.jobTitle}
        >
          <input
            onChange={(event) =>
              onChangeCurrentTargetValueString(
                setEditableSalaryHistory,
                event,
                "jobTitle",
              )
            }
            value={editableSalaryHistory.jobTitle}
          />
        </Labeled>
      </div>
    </Card>
  );
};

export default EmployeeSalaryExpectation;

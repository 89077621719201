const IconReorganize = ({ size = 24 }) => (
  <svg viewBox="0 0 48 48" width={size} height={size}>
    <g fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
      <path
        d="M13.1 28.4v5.5M11 12.9h4v16h-4zM13.1 7.9v6"
        style={{ fill: "#ffffff" }}
      />
    </g>
    <g fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
      <path
        d="M23.1 34.5V42M21 19.1h4v16h-4zM23.1 12.1V20"
        style={{ fill: "#ffffff" }}
      />
    </g>
    <g fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
      <path
        d="M5 35.9s0 9.3 9.8 5.8M12.4 39.3l3.5 2-2 3.5"
        style={{ fill: "#ffffff" }}
      />
    </g>
    <g fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
      <path
        d="M33 32.4v5.5M31 16.9h4v16h-4zM33 11.9v6"
        style={{ fill: "#ffffff" }}
      />
    </g>
    <g fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
      <path
        d="M43 11.9s0-9.3-9.8-5.8M35.5 8.5l-3.4-1.9 2-3.5"
        style={{ fill: "#ffffff" }}
      />
    </g>
  </svg>
);

export default IconReorganize;

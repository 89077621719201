const IconSave = () => (
  <svg width={18} height={18} viewBox="0 0 18 18">
    <path
      d="M17.9686 3.925V16.5C17.9686 16.9 17.8207 17.25 17.525 17.55C17.2293 17.85 16.8843 18 16.4901 18H1.70495C1.31068 18 0.965695 17.85 0.669993 17.55C0.374291 17.25 0.22644 16.9 0.22644 16.5V1.5C0.22644 1.1 0.374291 0.75 0.669993 0.45C0.965695 0.15 1.31068 0 1.70495 0H14.0998L17.9686 3.925ZM16.4901 4.6L13.4345 1.5H1.70495V16.5H16.4901V4.6ZM9.09171 14.875C9.80197 14.875 10.4076 14.6228 10.9087 14.1184C11.4097 13.614 11.6603 13.0015 11.6603 12.2809C11.6603 11.5603 11.4117 10.9458 10.9145 10.4375C10.4173 9.92917 9.81357 9.675 9.10329 9.675C8.39303 9.675 7.78738 9.92721 7.28633 10.4316C6.78528 10.936 6.53475 11.5485 6.53475 12.2691C6.53475 12.9897 6.78335 13.6042 7.28054 14.1125C7.77771 14.6208 8.38143 14.875 9.09171 14.875ZM3.01097 6.4H11.8327V2.825H3.01097V6.4ZM1.70495 4.6V16.5V1.5V4.6Z"
      fill="currentColor"
    />
  </svg>
);

export default IconSave;

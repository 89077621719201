import { useEffect, useRef, useState } from "react";

interface TextInputProps extends React.MeterHTMLAttributes<HTMLInputElement> {}

const TextInput = ({ value, ...rest }: TextInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value as string);
  }, [value]);

  return <input ref={ref} {...rest} value={inputValue} />;
};

export default TextInput;

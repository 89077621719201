const endpoints = {
  whoami: `${process.env["REACT_APP_API_URL"]}/user/whoami`,
  employees: (role: string) =>
    `${process.env["REACT_APP_API_URL"]}/employees/${role.toLowerCase()}`,
  employeeDetail: (id: string) =>
    `${process.env["REACT_APP_API_URL"]}/employees/${id}`,
  editEmployee: `${process.env["REACT_APP_API_URL"]}/editEmployee`,
  approveProposal: (id: string) =>
    `${process.env["REACT_APP_API_URL"]}/employees/${id}/approve`,
  overtakeProposal: (id: string) =>
    `${process.env["REACT_APP_API_URL"]}/employees/${id}/overtake`,
  releaseProposal: (id: string) =>
    `${process.env["REACT_APP_API_URL"]}/employees/${id}/release`,
  companyTree: () =>
    `${process.env["REACT_APP_API_URL"]}/employees/companyTree`,
  statistics: (viewType: string) =>
    `${process.env["REACT_APP_API_URL"]}/employees/view/statistics/${viewType}`,
  export: (role: string) =>
    `${
      process.env["REACT_APP_API_URL"]
    }/employees/${role.toLowerCase()}/export`,
};

export default endpoints;

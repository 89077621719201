const IconExit = () => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    <path
      d="M2 24C1.46667 24 1 23.8 0.6 23.4C0.2 23 0 22.5333 0 22V2C0 1.46667 0.2 1 0.6 0.6C1 0.2 1.46667 0 2 0H11.7V2H2V22H11.7V24H2ZM18.2 17.8333L16.7667 16.4L20.1667 13H8.5V11H20.1L16.7 7.6L18.1333 6.16667L24 12.0333L18.2 17.8333Z"
      fill="currentColor"
    />
  </svg>
);

export default IconExit;

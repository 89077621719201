import { Employee } from "types/Employee";
import ProposalAction from "components/base/ProposalAction";
import ApprovalStatus from "../../types/ApprovalStatus";
import { ModalProperties } from "../../types/ModalProperties";
import { useLanguage } from "../../contexts/LanguageContext";

interface EmployeeCommentProps {
  employee: Employee;
  setModalProperties?: (modalProperties: ModalProperties) => void;
  refetchFn: () => void;
  overtakeAllowed: boolean;
  validate?: () => string;
}

const EmployeeCommentAndApproval = ({
  employee,
  setModalProperties,
  refetchFn,
  overtakeAllowed,
  validate,
}: EmployeeCommentProps) => {
  const { messages } = useLanguage();
  const actionNecessary = employee.availableActions !== "NONE";
  const actionNotNecessary = employee.availableActions === "NONE";
  const notEditable =
    actionNotNecessary &&
    employee.currentStatusOfSalaryHistoryRecord !== "RELEASED" &&
    employee.currentApproverUpn;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div className="employee-comment">
        <div className="employee-comment-main">
          <div className="employee-comment-body">
            <div className="flex-row-items-between">
              <h2 style={{ marginBottom: 0 }}>{messages.commentLabel}</h2>
            </div>
            <p
              key={employee.upn + "-reason"}
              style={{
                height: employee.team.length > 0 ? "175px" : "90px",
                overflowY: "auto",
                whiteSpace: "pre-wrap",
              }}
            >
              {employee.currentSalaryHistory?.reason}
            </p>
          </div>
          {overtakeAllowed && (
            <ProposalAction
              className="employee-comment-cta"
              upn={employee.upn}
              name={employee.firstName + " " + employee.lastName}
              status={
                employee.currentStatusOfSalaryHistoryRecord as ApprovalStatus
              }
              availableActions={employee.availableActions}
              setModalProperties={setModalProperties}
              refetchFn={refetchFn}
              notEditable={!!notEditable}
              validate={validate}
            />
          )}
        </div>
        {actionNecessary && employee.availableActions !== "OVERTAKE" && (
          <div className="employee-comment-footer">
            <strong>{messages.approvalRequiredLabel}</strong>
          </div>
        )}
        {notEditable && (
          <div className="employee-comment-footer">
            <strong>
              {messages.approvalNextApproversLabel}
              {employee.currentApproverFirstName +
                " " +
                employee.currentApproverLastName}
            </strong>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeCommentAndApproval;

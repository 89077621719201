import { useEffect, useRef } from "react";

import round from "shared/round";

interface NumberInputProps extends React.MeterHTMLAttributes<HTMLInputElement> {
  onChangeNumber?: React.ChangeEventHandler<HTMLInputElement>;
  value: string | number;
}

const NumberInput = ({ value, onChangeNumber, ...rest }: NumberInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const last = useRef<{ number: number }>({ number: 0 });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = round(event.currentTarget.value);

    if (Number.isFinite(number)) {
      last.current.number = number;
      onChangeNumber && onChangeNumber(event);
    }
  };

  const onBlur = () => {
    if (ref.current && !Number.isFinite(+ref.current.value)) {
      ref.current && (ref.current.value = last.current.number.toString());
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
    event.key === "Enter" && onBlur();

  useEffect(() => {
    const rounded = round(value);

    if (Number.isFinite(rounded)) {
      last.current.number = rounded;
      ref.current && (ref.current.value = rounded.toString());
    }
  }, [value]);

  return (
    <input
      ref={ref}
      {...rest}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={onChange}
    />
  );
};

export default NumberInput;

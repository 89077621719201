import { createContext } from "react";

export type Notification = { header: string; content: string } | undefined;

const defaultValue: [
  Notification,
  React.Dispatch<React.SetStateAction<Notification>>
] = [undefined, () => undefined];

const NotificationContext = createContext(defaultValue);

export default NotificationContext;

const IconTally = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 48 48">
    <path
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M12 9v30M20 9v30M28 9v30M36 9v30M5 31l38-12"
    />
  </svg>
);

export default IconTally;

import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: String(process.env["REACT_APP_CLIENT_ID"]),
    authority: String(process.env["REACT_APP_AUTHORITY"]),
    redirectUri: String(process.env["REACT_APP_REDIRECT_URI"]),
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
});

export default msalInstance;

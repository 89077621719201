import { ReactElement } from "react";

interface ErrorMessageProps {
  message: string;
  additionalBodyElement?: ReactElement;
}

const ErrorMessage = ({
  message,
  additionalBodyElement,
}: ErrorMessageProps) => {
  return (
    <div className="error-message">
      <>
        {message}
        {additionalBodyElement}
      </>
    </div>
  );
};

export default ErrorMessage;

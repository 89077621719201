import Card from "components/base/Card";
import { EmployeeEditable } from "types/Employee";
import { Dispatch, SetStateAction } from "react";
import { useLanguage } from "../../contexts/LanguageContext";

interface EmployeeSalaryCommentProps {
  disabled: boolean;
  editableSalaryHistory: EmployeeEditable;
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>;
}

const EmployeeSalaryComment = ({
  disabled,
  editableSalaryHistory,
  setEditableSalaryHistory,
}: EmployeeSalaryCommentProps) => {
  const { messages } = useLanguage();

  return (
    <Card className="employee-salary-comment">
      <h2>{messages.comment}</h2>
      <textarea
        style={{ cursor: disabled ? "not-allowed" : "" }}
        disabled={disabled}
        onInput={(event) => {
          const textAreaValue = event.currentTarget.value;
          setEditableSalaryHistory((prevState) => ({
            ...prevState,
            reason: textAreaValue,
          }));
        }}
        value={editableSalaryHistory.reason}
      />
    </Card>
  );
};

export default EmployeeSalaryComment;

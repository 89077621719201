import { useContext } from "react";
import decode from "jwt-decode";

import AccessTokenContext from "contexts/AccessTokenContext";

const useRequestHeaders = () => {
  const accessToken = useContext(AccessTokenContext);

  if (accessToken) {
    const json = decode(accessToken) as any;

    return new Headers({
      leaderEmail: json.unique_name,
      Authorization: "Bearer " + accessToken,
      "content-type": "application/json",
    });
  }

  return new Headers();
};

export default useRequestHeaders;

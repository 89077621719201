import clsx from "clsx";

import IconCross from "components/icons/IconCross";
import { ReactElement } from "react";

interface ModalProps {
  header?: string;
  content?: string | ReactElement;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  showCloseButton?: boolean;
  confirmButtonText?: string;
  confirmButtonAction?: Function;
}

const Modal = ({
  header,
  content,
  confirmButtonText = "OK",
  confirmButtonAction,
  showCloseButton = true,
  visible,
  setVisible,
}: ModalProps) => {
  const onClose = () => setVisible(false);
  const onConfirm = () => {
    if (confirmButtonAction) {
      confirmButtonAction();
    }
    onClose();
  };

  return (
    <div className={clsx("modal", visible && "modal-active")}>
      <div className="modal-body">
        <h2>{header}</h2>
        {showCloseButton && (
          <button className="modal-close" onClick={onClose}>
            <IconCross />
          </button>
        )}
        <br />
        <br />
        {content}
        <button className="modal-confirm" onClick={onConfirm}>
          {confirmButtonText}
        </button>
      </div>
    </div>
  );
};

export default Modal;

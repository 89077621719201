const IconCross = () => (
  <svg width={14} height={14} viewBox="0 0 14 14">
    <path
      d="M1.22499 13.825L0.174988 12.775L5.94999 7.00005L0.174988 1.22505L1.22499 0.175049L6.99999 5.95005L12.775 0.175049L13.825 1.22505L8.04999 7.00005L13.825 12.775L12.775 13.825L6.99999 8.05005L1.22499 13.825Z"
      fill="currentColor"
    />
  </svg>
);

export default IconCross;

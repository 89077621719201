import { EmployeeEditable, IEmployeeProfile } from "types/Employee";
import FullName from "components/base/FullName";
import IconAccount from "components/icons/IconAccount";
import IconEye from "components/icons/IconEye";
import IconLeadLevel from "components/icons/IconLeadLevel";
import IconLocation from "components/icons/IconLocation";
import IconPencil from "components/icons/IconPencil";
import IconWorkingHour from "components/icons/IconWorkingHour";
import IconIncrease from "../icons/IconIncrease";
import ApprovalStatus from "../../types/ApprovalStatus";
import { Tooltip } from "react-tooltip";
import Button from "./Button";
import { useContext } from "react";
import { ModalProperties } from "../../types/ModalProperties";
import {
  areAllPropertiesDefined,
  EmployeeUpnNameMap,
  generateActionMessageBody,
} from "../Employees/functions";
import { postApproval, postOvertake } from "../../api";
import useRequestHeaders from "../../hooks/useRequestHeaders";
import NotificationContext from "../../contexts/NotificationContext";
import Seniority from "../../types/Seniority";
import IconChartLine from "../icons/IconChartLine";
import { isFirstSeniorityHigherThanSecond } from "../../utils/helper";
import { useLanguage } from "../../contexts/LanguageContext";
import { seniorityMessages } from "../../shared/messages";
import { Link } from "react-router-dom";
import IconReorganize from "../icons/IconReorganize";
import { getCurrentPlanningYear } from "../../shared/years";
import IconChartLineCustomer from "../icons/IconChartLineCustomer";

interface EmployeeProfileProps {
  employeeProfile: IEmployeeProfile;
  showApprovalStatus?: boolean;
  showSubEmployees?: boolean;
  setShowSubEmployees?: (show: boolean) => void;
  allOvertakeEmployees?: EmployeeUpnNameMap[];
  allApproveEmployees?: EmployeeUpnNameMap[];
  setModalProperties?: (modalProperties: ModalProperties) => void;
  empty?: boolean;
  refetchFn?: () => void;
  overtakeAllowed: boolean;
  showComparison?: boolean;
  editableSalaryHistory?: EmployeeEditable | null;
  ignoreMissingProps?: boolean;
}

const EmployeeProfile = ({
  employeeProfile,
  showApprovalStatus,
  showSubEmployees,
  setShowSubEmployees,
  allOvertakeEmployees,
  allApproveEmployees,
  setModalProperties,
  empty,
  refetchFn,
  overtakeAllowed,
  showComparison,
  editableSalaryHistory,
  ignoreMissingProps,
}: EmployeeProfileProps) => {
  const { messages } = useLanguage();

  const headers = useRequestHeaders();
  const setNotification = useContext(NotificationContext)[1];

  const workingHours =
    !empty && employeeProfile.hours
      ? employeeProfile.hours.toString() + " " + messages.weekLabel
      : "";

  const hasTeam = !!employeeProfile.teamSize && employeeProfile.teamSize > 0;

  const confirmAction = (type: string) => {
    const chainMultiplePromise = [] as Promise<any>[];
    const actionArray =
      type === "OVERTAKE" ? allOvertakeEmployees : allApproveEmployees;

    actionArray!
      .map((actionEmployees) => actionEmployees.upn)
      .forEach((upn) => {
        if (type === "APPROVE") {
          chainMultiplePromise.push(postApproval(upn, headers));
        } else {
          chainMultiplePromise.push(postOvertake(upn, headers));
        }
      });
    Promise.all(chainMultiplePromise).then(() => {
      if (refetchFn) {
        refetchFn();
      }
      setNotification(messages.approveSalaryProposal.succeed);
    });
  };

  const onClick = async (type: string, dataSet: EmployeeUpnNameMap[]) => {
    if (setModalProperties) {
      setModalProperties({
        visible: true,
        header: messages.actionHeader[type],
        content: generateActionMessageBody(
          messages.actionText[type],
          type,
          dataSet,
        ),
        showCloseButton: true,
        confirmButtonText: "OK",
        confirmButtonAction: () => confirmAction(type),
      });
    }
  };

  const getVariablePercent = () => {
    if (
      editableSalaryHistory &&
      editableSalaryHistory.variable &&
      editableSalaryHistory.fixum
    ) {
      return (
        (editableSalaryHistory.variable /
          (editableSalaryHistory.fixum +
            editableSalaryHistory.variable +
            editableSalaryHistory.companyCar ?? 0)) *
        100
      );
    }
    return employeeProfile.currentSalaryHistory?.variablePercent;
  };

  /**
   * show employee details from employeeProfile
   */
  const showGivenDetails = () =>
    !empty && (
      <ul>
        <li
          className={
            !employeeProfile.currentSalaryHistory &&
            employeeProfile.lastSalaryHistory &&
            employeeProfile.jobTitle !==
              employeeProfile.lastSalaryHistory.jobTitle
              ? "flex-row-items-center active"
              : "flex-row-items-center"
          }
        >
          <strong>{employeeProfile.jobTitle}</strong>
        </li>
        <li
          className={
            !employeeProfile.currentSalaryHistory &&
            employeeProfile.lastSalaryHistory &&
            isFirstSeniorityHigherThanSecond(
              employeeProfile.seniority,
              employeeProfile.lastSalaryHistory.seniority,
            )
              ? "flex-row-items-center active"
              : "flex-row-items-center"
          }
        >
          <IconLeadLevel />
          <small>
            {seniorityMessages[employeeProfile.seniority as Seniority].label}
          </small>
          {!employeeProfile.currentSalaryHistory &&
            employeeProfile.lastSalaryHistory &&
            isFirstSeniorityHigherThanSecond(
              employeeProfile.seniority,
              employeeProfile.lastSalaryHistory.seniority,
            ) && (
              <div style={{ marginLeft: "4px" }}>
                <IconIncrease />
              </div>
            )}
        </li>
        <li>
          <IconWorkingHour />
          <small>{workingHours}</small>
        </li>
        <li>
          <IconLocation />
          <small>{employeeProfile.location.toLocaleLowerCase()}</small>
        </li>
        <li
          data-tooltip-id="tooltip-pquota"
          data-tooltip-content={
            employeeProfile.projectQuota !== null &&
            employeeProfile.costQuota !== null
              ? messages.projectQuota + "/" + messages.costRelief
              : messages.projectQuota
          }
        >
          <Tooltip id="tooltip-pquota" />
          <IconChartLine size={16} />
          <small>
            {(employeeProfile.projectQuota ?? 0).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }) +
              "%" +
              (employeeProfile.costQuota
                ? ` / ${employeeProfile.costQuota?.toLocaleString(undefined, {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })}%`
                : "")}
          </small>
        </li>
        <li
          data-tooltip-id="tooltip-customer-pquota"
          data-tooltip-content={messages.customerProjectQuota}
        >
          <Tooltip id="tooltip-customer-pquota" />
          <IconChartLineCustomer size={16} />
          <small>
            {(employeeProfile.customerProjectQuota ?? 0).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              },
            ) + "%"}
          </small>
        </li>
        {showComparison && employeeProfile.lastSalaryHistory !== undefined && (
          <li
            data-tooltip-id="tooltip-var-port"
            data-tooltip-content={messages.variablePortion}
          >
            <Tooltip id="tooltip-var-port" />
            <IconReorganize size={16} />
            <small>
              {(
                employeeProfile.lastSalaryHistory?.variablePercent ?? 0
              ).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }) + "%"}
            </small>
          </li>
        )}
      </ul>
    );

  /**
   * show the newest employee details if comparison is to be shown
   */
  const showNewDetails = () => (
    <div
      className="employee-details"
      style={{ paddingLeft: "40px", marginTop: "-5px" }}
    >
      {!empty && employeeProfile.currentSalaryHistory && (
        <ul>
          <li style={{ marginBottom: "1rem" }}>
            <small>{getCurrentPlanningYear()}:</small>
          </li>
          <li
            className={
              employeeProfile.currentSalaryHistory &&
              employeeProfile.currentSalaryHistory.jobTitle !==
                employeeProfile.jobTitle
                ? "active"
                : ""
            }
          >
            <strong>{employeeProfile.currentSalaryHistory.jobTitle}</strong>
          </li>
          <li
            className={
              employeeProfile.currentSalaryHistory &&
              isFirstSeniorityHigherThanSecond(
                employeeProfile.currentSalaryHistory.seniority,
                employeeProfile.seniority,
              )
                ? "flex-row-items-center active"
                : "flex-row-items-center"
            }
          >
            <IconLeadLevel />
            <small>
              {
                seniorityMessages[
                  employeeProfile.currentSalaryHistory.seniority as Seniority
                ].label
              }
            </small>
            {employeeProfile.currentSalaryHistory &&
              isFirstSeniorityHigherThanSecond(
                employeeProfile.currentSalaryHistory.seniority,
                employeeProfile.seniority,
              ) && (
                <div style={{ marginLeft: "4px" }}>
                  <IconIncrease />
                </div>
              )}
          </li>
          <li
            className={
              employeeProfile.currentSalaryHistory &&
              employeeProfile.currentSalaryHistory.hours !==
                employeeProfile.hours
                ? "active"
                : ""
            }
          >
            <IconWorkingHour />
            <small>
              {employeeProfile.currentSalaryHistory.hours !== undefined
                ? employeeProfile.currentSalaryHistory.hours.toString() +
                  " " +
                  messages.weekLabel
                : ""}
            </small>
          </li>
          <li
            className={
              employeeProfile.currentSalaryHistory &&
              employeeProfile.currentSalaryHistory.teamlead !==
                employeeProfile.teamLead
                ? "active"
                : ""
            }
          >
            <small>
              {employeeProfile.currentSalaryHistory.teamlead !== undefined
                ? employeeProfile.currentSalaryHistory.teamlead
                  ? messages.responsibility
                  : messages.information.noResponsibility
                : ""}
            </small>
          </li>
          <li />
          <li />
          <li
            className={
              employeeProfile.currentSalaryHistory.variablePercent !==
              employeeProfile.lastSalaryHistory?.variablePercent
                ? "active"
                : ""
            }
          >
            <IconReorganize size={16} />
            <small>
              {(getVariablePercent() ?? 0).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }) + "%"}
            </small>
          </li>
        </ul>
      )}
    </div>
  );

  return (employeeProfile?.upn &&
    areAllPropertiesDefined(employeeProfile, ignoreMissingProps || empty)) ||
    empty ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="employee-profile">
        <div className="employee-status">
          <IconAccount size={showApprovalStatus ? 120 : 155} />
          {!empty && showApprovalStatus && (
            <>
              <small>
                {
                  messages.approvalStatus[
                    employeeProfile.approvalStatus as ApprovalStatus
                  ]
                }
              </small>
              <Link
                to={`/employee-salary/${employeeProfile.upn}`}
                onClick={() =>
                  sessionStorage.setItem("lastVisitedUpn", employeeProfile.upn)
                }
              >
                {employeeProfile.availableActions === "APPROVE" ? (
                  <IconPencil />
                ) : (
                  <IconEye />
                )}
              </Link>
            </>
          )}
        </div>
        <div className="employee-details">
          <h2>
            <FullName
              firstName={employeeProfile.firstName}
              lastName={employeeProfile.lastName}
              twoLine={showApprovalStatus}
            />
          </h2>
          {showGivenDetails()}
        </div>
        {showComparison && showNewDetails()}
      </div>
      {hasTeam && (
        <div className="mb-8 flex-column-content-center">
          <div className="mb-8 flex-row-items-center justify-center">
            <Button
              type="secondary"
              disabled={!allApproveEmployees?.length}
              caption={`${messages.actionHeader.APPROVE} (${allApproveEmployees?.length})`}
              clickFn={() => onClick("APPROVE", allApproveEmployees ?? [])}
              actionType="action"
            />
            {overtakeAllowed && (
              <Button
                type="secondary"
                disabled={!allOvertakeEmployees?.length}
                caption={`${messages.actionHeader.OVERTAKE} (${allOvertakeEmployees?.length})`}
                clickFn={() => onClick("OVERTAKE", allOvertakeEmployees ?? [])}
                actionType="warning"
              />
            )}
          </div>
          <Button
            type={showSubEmployees ? "primary" : "secondary"}
            caption={
              showSubEmployees
                ? messages.actionHeader.HIDE_EMPLOYEES
                : messages.actionHeader.SHOW_EMPLOYEES
            }
            clickFn={() =>
              setShowSubEmployees
                ? setShowSubEmployees(!showSubEmployees)
                : undefined
            }
          ></Button>
        </div>
      )}
    </div>
  ) : (
    <span>Data Error {employeeProfile.upn}</span>
  );
};

export default EmployeeProfile;

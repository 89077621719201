import clsx from "clsx";

interface ButtonProps {
  type: "primary" | "secondary";
  caption: string;
  clickFn: () => void;
  actionType?: "warning" | "action";
  disabled?: boolean;
  className?: string;
}

const Button = ({
  type,
  caption,
  clickFn,
  actionType,
  disabled,
  className,
}: ButtonProps) => {
  return (
    <button
      style={{ marginRight: "8px" }}
      disabled={disabled}
      className={clsx(
        className,
        {
          "primary-button": type === "primary",
          "secondary-button": type !== "primary",
        },
        {
          "warning-button-color": actionType && actionType !== "action",
          "action-button-color": actionType && actionType === "action",
        },
      )}
      onClick={clickFn}
    >
      {caption}
    </button>
  );
};

// className={clsx("action-button", className, {
//       "action-button-warning": warning,
//       "action-button-action": action,
//     })}

export default Button;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import Login from "components/Login";
import Header from "components/base/Header";
import AccessTokenContextProvider from "components/AccessTokenContextProvider";
import RoleContextProvider from "components/RoleContextProvider";
import EmployeeSalary from "components/EmployeeSalary";
import PageNotFound from "components/PageNotFound";
import Notification from "components/Notification";
import NotificationContextProvider from "components/NotificationContextProvider";
import msalInstance from "shared/msal.config";

import "styles/app.scss";
import EmployeesOverview from "./Employees/EmployeesOverview";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NoAccess from "../NoAccess";
import { LanguageProvider } from "../contexts/LanguageContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <EmployeesOverview />,
  },
  {
    path: "/employee-salary/:employeeUPN",
    element: <EmployeeSalary />,
  },
  {
    path: "/NoAccess",
    element: <NoAccess />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <LanguageProvider>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <NotificationContextProvider>
          <Header />
          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <AccessTokenContextProvider>
              <RoleContextProvider>
                <RouterProvider router={router} />
              </RoleContextProvider>
            </AccessTokenContextProvider>
          </AuthenticatedTemplate>
          <Notification />
        </NotificationContextProvider>
      </QueryClientProvider>
    </MsalProvider>
  </LanguageProvider>
);

export default App;

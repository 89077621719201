import { useMsal } from "@azure/msal-react";

import Modal from "components/base/Modal";
import { useLanguage } from "../contexts/LanguageContext";

const Login = () => {
  const msal = useMsal();
  const { messages } = useLanguage();

  const initializeSignIn = async () => {
    await msal.instance.loginRedirect({
      scopes: [String(process.env["REACT_APP_SCOPE"])],
      account: msal.accounts[0],
    });
  };

  return (
    <Modal
      visible
      header={messages.welcome}
      content={messages.notLogin}
      setVisible={initializeSignIn}
      showCloseButton={false}
      confirmButtonText={messages.login}
    />
  );
};

export default Login;

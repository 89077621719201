import FilterUI from "../base/FilterUI";
import { Filter } from "../../types/Filter";
import Switch from "../base/Switch";
import Slider from "../base/Slider";
import { ChangeEvent, Dispatch, SetStateAction, useContext } from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import Button from "../base/Button";
import { Employee } from "../../types/Employee";
import { ModalProperties } from "../../types/ModalProperties";
import {
  EmployeeUpnNameMap,
  findEmployeesFromArrayWithAction,
  generateActionMessageBody,
} from "./functions";
import { postApproval, postOvertake } from "../../api";
import useRequestHeaders from "../../hooks/useRequestHeaders";
import NotificationContext from "../../contexts/NotificationContext";
import TextInput from "../base/TextInput";

interface EmployeesFilterProps {
  filter: Filter;
  setFilter: Dispatch<SetStateAction<Filter>>;
  employees: Employee[];
  setModalProperties?: (modalProperties: ModalProperties) => void;
  overtakeAllowed: boolean;
  refetchFn?: () => void;
  view: string;
}
const EmployeesFilter = ({
  filter,
  setFilter,
  employees,
  setModalProperties,
  overtakeAllowed,
  refetchFn,
  view,
}: EmployeesFilterProps) => {
  const { messages } = useLanguage();
  const headers = useRequestHeaders();
  const setNotification = useContext(NotificationContext)[1];

  const allReleaseEmployees = findEmployeesFromArrayWithAction(
    employees,
    "RELEASE",
  );
  const allApproveEmployees = findEmployeesFromArrayWithAction(
    employees,
    "APPROVE",
  );
  const allOvertakeEmployees = findEmployeesFromArrayWithAction(
    employees,
    "OVERTAKE",
  );

  const confirmAction = (type: string) => {
    const chainMultiplePromise = [] as Promise<any>[];
    const actionArray =
      type === "OVERTAKE" ? allOvertakeEmployees : allApproveEmployees;

    actionArray
      .map((actionEmployees) => actionEmployees.upn)
      .forEach((upn) => {
        if (type === "APPROVE") {
          chainMultiplePromise.push(postApproval(upn, headers));
        } else {
          chainMultiplePromise.push(postOvertake(upn, headers));
        }
      });
    Promise.all(chainMultiplePromise).then(() => {
      if (refetchFn) {
        refetchFn();
      }
      setNotification(messages.approveSalaryProposal.succeed);
    });
  };

  const onClick = async (type: string, dataSet: EmployeeUpnNameMap[]) => {
    if (setModalProperties) {
      setModalProperties({
        visible: true,
        header: messages.actionHeader[type],
        content: generateActionMessageBody(
          messages.actionText[type],
          type,
          dataSet,
        ),
        showCloseButton: true,
        confirmButtonText: "OK",
        confirmButtonAction: () => confirmAction(type),
      });
    }
  };

  const setFilterValue = (
    messagesObject: any,
    type: string,
    eventTargetValue: string,
  ) => {
    if (
      messagesObject[type === "seniority" ? "seniorityLevel" : type][
        eventTargetValue
      ]
    ) {
      setFilter((prevState) => ({
        ...prevState,
        [type]: eventTargetValue,
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        [type]: "",
      }));
    }
  };

  return (
    <>
      <div className="filter-header">
        <FilterUI
          caption={messages.genderCaption}
          changeFn={(eventTargetValue) =>
            setFilterValue(messages, "gender", eventTargetValue)
          }
          optionObject={messages.gender}
          selected={filter.gender}
        />
        <FilterUI
          caption={messages.locationCaption}
          changeFn={(eventTargetValue) =>
            setFilterValue(messages, "location", eventTargetValue)
          }
          optionObject={messages.location}
          selected={filter.location}
        />
        <FilterUI
          caption={messages.seniorityLevelCaption}
          changeFn={(eventTargetValue) =>
            setFilterValue(messages, "seniority", eventTargetValue)
          }
          optionObject={messages.seniorityLevel}
          selected={filter.seniority}
        />
        <FilterUI
          caption={messages.statusCaption}
          changeFn={(eventTargetValue) =>
            setFilterValue(messages, "status", eventTargetValue)
          }
          optionObject={messages.status}
          selected={filter.status}
        />
      </div>
      <div className="filter-header">
        <div className="flex-column-content-center" />
        <div className="flex-column-content-center">
          <div className="flex-row">
            <TextInput
              placeholder={messages.firstname}
              value={filter.firstname}
              onChange={(event) => {
                setFilter((prevState) => ({
                  ...prevState,
                  firstname: (event as ChangeEvent<HTMLInputElement>).target
                    .value,
                }));
              }}
            />
          </div>
        </div>
        <div className="flex-column-content-center">
          <div className="flex-row">
            <TextInput
              placeholder={messages.lastname}
              value={filter.lastname}
              onChange={(event) =>
                setFilter((prevState) => ({
                  ...prevState,
                  lastname: (event as ChangeEvent<HTMLInputElement>).target
                    .value,
                }))
              }
            />
          </div>
        </div>
        <div className="flex-column-content-center" />
      </div>
      <div className="filter-header">
        <div className="flex-column-content-center">
          <div className="flex-row">
            <span style={{ marginRight: "4px" }}>
              {messages.responsibility}{" "}
            </span>
            <Switch
              onClick={() =>
                setFilter((prevState) => ({
                  ...prevState,
                  responsibility: !prevState.responsibility,
                }))
              }
              checked={filter.responsibility}
            />
          </div>
        </div>
        <div className="flex-column-content-center">
          <div className="flex-row">
            <span style={{ marginRight: "4px" }}>{messages.promotion} </span>
            <Switch
              onClick={() =>
                setFilter((prevState) => ({
                  ...prevState,
                  promotion: !prevState.promotion,
                }))
              }
              checked={filter.promotion}
            />
          </div>
        </div>
        <div className="flex-column-content-center">
          <Slider
            changeFn={(eventTargetValue) => {
              setFilter((prevState) => ({
                ...prevState,
                decrease:
                  Number(eventTargetValue) < 0 ? Number(eventTargetValue) : 0,
                increase:
                  Number(eventTargetValue) > 0 ? Number(eventTargetValue) : 0,
              }));
            }}
            minValue={-25}
            maxValue={25}
            value={filter.increase}
          />
        </div>
        <div className="flex-column-content-center">
          <div className="flex-row-items-center">
            {view === "BOARD" ? (
              <Button
                type="secondary"
                disabled={!allReleaseEmployees.length}
                caption={`${messages.actionHeader.RELEASE} (${allReleaseEmployees.length})`}
                clickFn={() => onClick("RELEASE", allReleaseEmployees)}
                actionType="action"
              />
            ) : (
              view !== "HR" && (
                <>
                  <Button
                    type="secondary"
                    disabled={!allApproveEmployees.length}
                    caption={`${messages.actionHeader.APPROVE} (${allApproveEmployees.length})`}
                    clickFn={() => onClick("APPROVE", allApproveEmployees)}
                    actionType="action"
                  />
                  {overtakeAllowed && (
                    <Button
                      type="secondary"
                      disabled={!allOvertakeEmployees.length}
                      caption={`${messages.actionHeader.OVERTAKE} (${allOvertakeEmployees.length})`}
                      clickFn={() => onClick("OVERTAKE", allOvertakeEmployees)}
                      actionType="warning"
                    />
                  )}
                </>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeesFilter;

/**
 * Returns the year for which salaries are currently being planned
 */
export const getCurrentPlanningYear = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  return currentMonth < 6 ? currentYear : currentYear + 1;
};

/**
 * Returns the year previous to the now being planned year
 */
export const getPreviousYear = () => getCurrentPlanningYear() - 1;

const IconEye = () => (
  <svg width={22} height={15} viewBox="0 0 22 15">
    <path
      d="M11.003 11.75C12.1843 11.75 13.1875 11.3365 14.0125 10.5096C14.8375 9.6826 15.25 8.67843 15.25 7.49705C15.25 6.31568 14.8365 5.3125 14.0096 4.4875C13.1826 3.6625 12.1784 3.25 10.9971 3.25C9.81568 3.25 8.8125 3.66348 7.9875 4.49045C7.1625 5.3174 6.75 6.32157 6.75 7.50295C6.75 8.68432 7.16348 9.6875 7.99045 10.5125C8.8174 11.3375 9.82157 11.75 11.003 11.75ZM10.9941 10.3C10.2147 10.3 9.55417 10.0272 9.0125 9.48163C8.47083 8.93603 8.2 8.27353 8.2 7.49413C8.2 6.71471 8.47279 6.05417 9.01837 5.5125C9.56397 4.97083 10.2265 4.7 11.0059 4.7C11.7853 4.7 12.4458 4.97279 12.9875 5.51837C13.5292 6.06397 13.8 6.72647 13.8 7.50587C13.8 8.28529 13.5272 8.94583 12.9816 9.4875C12.436 10.0292 11.7735 10.3 10.9941 10.3ZM11 15C8.56667 15 6.36667 14.3083 4.4 12.925C2.43333 11.5417 0.966667 9.73333 0 7.5C0.966667 5.26667 2.43333 3.45833 4.4 2.075C6.36667 0.691667 8.56667 0 11 0C13.4333 0 15.6333 0.691667 17.6 2.075C19.5667 3.45833 21.0333 5.26667 22 7.5C21.0333 9.73333 19.5667 11.5417 17.6 12.925C15.6333 14.3083 13.4333 15 11 15ZM10.9958 13.5C13.0153 13.5 14.8708 12.9542 16.5625 11.8625C18.2542 10.7708 19.5417 9.31667 20.425 7.5C19.5417 5.68333 18.2556 4.22917 16.5667 3.1375C14.8779 2.04583 13.0237 1.5 11.0042 1.5C8.98474 1.5 7.12917 2.04583 5.4375 3.1375C3.74583 4.22917 2.45 5.68333 1.55 7.5C2.45 9.31667 3.74442 10.7708 5.43327 11.8625C7.12214 12.9542 8.97631 13.5 10.9958 13.5Z"
      fill="currentColor"
    />
  </svg>
);

export default IconEye;

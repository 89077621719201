import clsx from "clsx";

interface SwitchProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  checked?: boolean;
}

const classNames = ({ checked, className }: SwitchProps) =>
  clsx("switch", checked && "switch-checked", className);

const Switch = ({ children, ...rest }: SwitchProps) => {
  return (
    <button {...rest} className={classNames(rest)}>
      {children}
    </button>
  );
};

export default Switch;

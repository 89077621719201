import { saveAs } from "file-saver";
import { useLanguage } from "../../contexts/LanguageContext";
import { exportEmployeesByRole } from "../../api";
import React from "react";
import Button from "./Button";
import {
  checkIfFilterIsInactive,
  filterRequestValues,
} from "../Employees/functions";
import { Filter } from "../../types/Filter";
import useRequestHeaders from "../../hooks/useRequestHeaders";

interface ExportButtonProps {
  role: string;
  filter: Filter;
  className?: string;
}

const ExportButton = ({ className, role, filter }: ExportButtonProps) => {
  const headers = useRequestHeaders();
  const { messages } = useLanguage();

  const triggerExport = async () => {
    await exportEmployeesByRole(
      role,
      headers,
      checkIfFilterIsInactive(filter) ? {} : filterRequestValues(filter),
    ).then(
      (blob) =>
        blob &&
        saveAs(
            new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), blob], {type: "text/csv;charset=UTF-8"}),
          `${role}-export-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.csv`,
        ),
    );
  };

  return (
    <Button
      className={className}
      type={"primary"}
      caption={messages.export}
      clickFn={triggerExport}
    ></Button>
  );
};

export default ExportButton;

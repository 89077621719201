import { ReactNode, useState } from "react";

import RoleContext from "contexts/RoleContext";
import { getWhoami } from "../api";
import useRequestHeaders from "../hooks/useRequestHeaders";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "../contexts/LanguageContext";

interface EmployeesContextProviderProps {
  children?: ReactNode | undefined;
}

const RoleContextProvider = ({ children }: EmployeesContextProviderProps) => {
  const headers = useRequestHeaders();
  const { messages } = useLanguage();

  const [role, setRole] = useState("");

  const roleQuery = useQuery(["role"], () =>
    getWhoami(headers).then((role) => {
      setRole(role);
      return role;
    }),
  );

  return (
    <RoleContext.Provider value={[role]}>
      {roleQuery.status !== "success" && (
        <div style={{ margin: "32px" }}>
          {messages.information.connectionInfo}
        </div>
      )}
      {roleQuery.status === "success" && children}
    </RoleContext.Provider>
  );
};

export default RoleContextProvider;
